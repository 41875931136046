import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 三项电表
 * @param params
 */
export function getThreePhaseList(params) {
  return request({
    url: envInfo.bgApp.stationBmsPath + '/threePhaseAnalyze',
    method: 'get',
    params,
  });
}

/**
 * 获取楼栋数据
 * @param params
 */
export function getBuildingListByProjectName(params) {
  return request({
    url: envInfo.bgApp.stationPath + '/hydropower/buildList',
    method: 'get',
    params,
  });
}

/**
 * 获取楼层数据
 * @param params
 */
export function getFloorListByBuild(params) {
  return request({
    url: envInfo.bgApp.stationPath + '/hydropower/floorList',
    method: 'get',
    params,
  });
}

/**
 * 获取房间数据
 * @param params
 */
export function getRoomListByFloor(params) {
  return request({
    url: envInfo.bgApp.stationPath + '/hydropower/roomList',
    method: 'get',
    params,
  });
}

/**
 * 园区租户每月用水统计
 * @param params
 */
export function parkWaterStatistics(params) {
  return request({
    url: envInfo.bgApp.stationPath + '/hydropower/waterStatistics',
    method: 'get',
    params,
  });
}

/**
 * 园区租户每月用电统计
 * @param params
 */
export function parkElectricStatistics(params) {
  return request({
    url: envInfo.bgApp.stationPath + '/hydropower/electricityStatistics',
    method: 'get',
    params,
  });
}

/**
 * 园区租户每月用电统计
 * @param params
 */
export function getWaterElectricDeviceList(params) {
  return request({
    url: envInfo.bgApp.stationPath + '/hydropower/deviceList',
    method: 'get',
    params,
  });
}

/**
 * 园区租户用电情况
 * @param params
 */
export function getElectricityCondition(params) {
  return request({
    url: envInfo.bgApp.stationPath + '/hydropower/electricityCondition',
    method: 'get',
    params,
  });
}

/**
 * 园区租户用水情况
 * @param params
 */
export function getWaterCondition(params) {
  return request({
    url: envInfo.bgApp.stationPath + '/hydropower/waterCondition',
    method: 'get',
    params,
  });
}

/**
 * 园区租户用水情况导出
 * @param params
 */
export function waterConditionExport(params) {
  return request({
    url: envInfo.bgApp.stationPath + '/hydropower/waterConditionExport',
    method: 'get',
    params,
  });
}
/**
 * 园区租户用电情况导出
 * @param params
 */
export function electricityConditionExport(params) {
  return request({
    url: envInfo.bgApp.stationPath + '/hydropower/electricityConditionExport',
    method: 'get',
    params,
  });
}

/**
 * 锦绣三期用电情况统计
 * @param params
 */
export function jx3MonthTotalPowerConsumption(params) {
  return request({
    url:
      envInfo.bgApp.cockpitDataPath + '/energy/jx3MonthTotalPowerConsumption',
    method: 'get',
    params,
  });
}

/**
 * 锦绣三期用电情况导出
 * @param params
 */
export function jx3MonthPowerConsumptionExport(params) {
  return request({
    url:
      envInfo.bgApp.cockpitDataPath + '/energy/jx3MonthPowerConsumptionExport',
    method: 'get',
    params,
  });
}
