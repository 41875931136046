<template>
  <div class="app-container elecricity-consumption-page">
    <el-row :gutter="20" class="filter-line">
      <el-col :span="6">
        <el-date-picker
          class="filter-item"
          v-model="params.year"
          type="year"
          value-format="yyyy"
          :picker-options="pickerOptions"
          placeholder="选择年"
          :clearable="false"
          @change="getTableData"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="6">
        <el-select
          class="filter-item"
          v-model="params.jfx"
          clearable
          placeholder="请选择计费项"
          @change="getTableData"
        >
          <el-option
            v-for="item in jfxOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-input
          class="filter-item"
          v-model="params.zjfx"
          clearable
          @keyup.enter.native="getTableData"
          placeholder="请输入子计费项"
        />
      </el-col>
      <el-col :span="6">
        <r-button plain @click="getTableData">查询</r-button>
        <r-button plain @click="datasExports">导出</r-button>
      </el-col>
    </el-row>
    <div class="table-container" v-loading="loading">
      <el-table
        :data="tableData"
        :span-method="arraySpanMethod"
        :header-cell-style="headerCellStyle"
        :row-class-name="tableRowClassName"
        border
        style="width: 100%"
        max-height="calc(100vh - 186px)"
        height="calc(100vh - 186px)"
      >
        <el-table-column
          prop="jfx"
          show-overflow-tooltip
          fixed="left"
          label="计费项"
        >
        </el-table-column>
        <el-table-column
          prop="zjfx"
          show-overflow-tooltip
          fixed="left"
          label="子计费项"
        >
        </el-table-column>
        <el-table-column prop="month1" label="1月"></el-table-column>
        <el-table-column prop="month2" label="2月"></el-table-column>
        <el-table-column prop="month3" label="3月"></el-table-column>
        <el-table-column prop="month4" label="4月"></el-table-column>
        <el-table-column prop="month5" label="5月"></el-table-column>
        <el-table-column prop="month6" label="6月"></el-table-column>
        <el-table-column prop="month7" label="7月"></el-table-column>
        <el-table-column prop="month8" label="8月"></el-table-column>
        <el-table-column prop="month9" label="9月"></el-table-column>
        <el-table-column prop="month10" label="10月"></el-table-column>
        <el-table-column prop="month11" label="11月"></el-table-column>
        <el-table-column prop="month12" label="12月"></el-table-column>
        <el-table-column
          prop="total"
          fixed="right"
          label="小计"
        ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { jx3MonthTotalPowerConsumption } from "@/api/ruge/bmsNew/electricityMeter.js";
import moment from "moment";
import { findLookupItem } from "@/api/ruge/lookup/lookup";
import { envInfo } from "@/constants/envInfo";

export default {
  name: "elecricity-consumption-page",
  data() {
    return {
      loading: false,
      tableData: [],
      jfxOptions: [],
      params: {
        year: String(new Date().getFullYear()),
        project: "GS3",
        jfx: "",
        zjfx: "",
      },
      pickerOptions: {
        //根据所选时间筛选可选日期
        disabledDate: (time) => {
          let cur = moment(new Date().getTime());
          return time.getTime() > cur.startOf("year").valueOf();
        },
      },
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    tableRowClassName(params) {
      const { row, rowIndex } = params;
      row.index = rowIndex;
      if (rowIndex === this.tableData.length - 1) {
        return `tr-fixed fixed-row`;
      } else {
        return ``;
      }
    },
    datasExports() {
      let url = `${envInfo.bgApp.cockpitDataPath}/energy/jx3MonthPowerConsumptionExport?`;
      for (let key in this.params) {
        if (this.params[key]) {
          url += `${key}=${this.params[key]}&`;
        }
      }
      const a = document.createElement("a");
      a.href = url;
      a.click();
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === this.tableData.length - 1) {
        if (columnIndex === 0) {
          return [1, 2];
        } else if (columnIndex === 1) {
          return [0, 0];
        }
      }
    },
    headerCellStyle: () => {
      return "background-color: #F0F4FA; color: #5D687C; ";
    },
    initDatas() {
      findLookupItem("Charge_Type").then((res) => {
        this.jfxOptions = res.map((item) => {
          return {
            label: item.itemName,
            value: item.itemCode,
          };
        });
      });
      this.getTableData();
    },
    getTableData() {
      this.loading = true;
      jx3MonthTotalPowerConsumption(this.params)
        .then((res) => {
          // jfx zjfx monthDate1 monthDate2 monthDate3 monthDate4 ...
          const itemLabelMap = {
            0: "jfx",
            1: "zjfx",
            2: "month1",
            3: "month2",
            4: "month3",
            5: "month4",
            6: "month5",
            7: "month6",
            8: "month7",
            9: "month8",
            10: "month9",
            11: "month10",
            12: "month11",
            13: "month12",
            14: "total",
          };
          this.tableData = res.map((item, index) => {
            const tempObj = {};
            item.forEach((single, index) => {
              tempObj[itemLabelMap[index]] = single || "-";
            });
            return tempObj;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.elecricity-consumption-page {
  .filter-line {
    .filter-item {
      width: 100%;
    }
  }
  .table-container {
    margin-top: 20px;
  }
  /deep/.el-table .fixed-row {
    display: table-row;
    position: sticky;
    bottom: 0;
    width: 100%;
    td {
      border: 1px solid #f3f5fa;
      background: #fff;
    }
  }
  /deep/.el-table .cell {
    white-space: pre !important;
  }
}
</style>